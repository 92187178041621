export const indexMenuLinks = [
  {
    name: 'Unternehmensberatung',
    link: '/#unternehmensberatung',
    icon: 'user-tie',
  },
  {
    name: 'Finanzbuchhaltung',
    link: '/#finanzbuchhaltung',
    icon: 'terminal',
  },
  {
    name: 'Lohnbuchhaltung',
    link: '/#lohnbuchhaltung',
    icon: 'feather-alt',
  },
  {
    name: 'Impressum',
    link: '/#impressum',
    icon: 'envelope',
  },
];

export const blogMenuLinks = [
  {
    name: 'Portfolio',
    link: '/',
    icon: 'code',
  },
  {
    name: 'Blog',
    link: '/blog',
    icon: 'feather-alt',
  },
  {
    name: 'Tags',
    link: '/tags',
    icon: 'tags',
  },
];
