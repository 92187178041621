import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../assets/logo.svg';
import SocialIcons from './social-icons';
import { socialIconList } from './_config/social-icon-list';
import { mq } from './_shared/media';
import { StyledIndexNumber } from './_shared/styled-index-number';
import { Drawer, Button, Radio, Space } from 'antd';
import { FC, useState } from 'react';


import {
  AlignRightOutlined
} from '@ant-design/icons';
const StyledHeader = styled.header`
  height: var(--header-height);
  flex-shrink: 0;
  background-color: var(--bg-content-color);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-left);
  position: fixed;
  width: 100%;
  z-index: 111;
  ${mq.gt.sm} {
    padding-left: 0px;
    position: relative;
  }
`;
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  
  max-width: 1000px;
  margin: 0 auto;
`;
const StyledHomeLink = styled(Link)`
  text-decoration: none;
  padding-left: 20px;
  ${mq.gt.sm} {
    padding-left: 0px;
  }
`;
const StyledLogo = styled(Logo)`
  width: var(--header-height);
  height: var(--header-height);
  fill: var(--title-color);

  &:hover path.level-2 {
    fill: var(--primary-color);
  }
`;
const StyledMobileNav = styled.nav`
  flex: 1;
  height: 100%;
  display: inline;
  font-size: 15px;
  button{
    float: right;
    top: 15px;
    position: relative;
    right: 20px;
    background: none;
    color: white;
    font-size: 20px;
    border: none;
}
  }
  ${mq.gt.sm} {
    display: none;
    align-items: stretch;
    margin-left: 0.75rem;
  }

  & > a {
    color: var(--title-color);
    &:hover {
      color: var(--primary-color);
    }
  }
`;


const StyledNav = styled.nav`
  flex: 1;
  height: 100%;
  display: none;
  font-size: 15px;
  ${mq.gt.sm} {
    display: flex;
    align-items: stretch;
    margin-left: 0.75rem;
  }

  & > a {
    color: var(--title-color);
    &:hover {
      color: var(--primary-color);
    }
  }
`;
 
const StyledNavLink = styled(Link)`
  position: relative;
  margin: 0 0.5rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 100%;
    bottom: 0;
    background: var(--primary-color);
    height: 2px;
    transition: right var(--transition-fast) ease-out;
  }

  &:hover:before {
    right: 0;
  }
`;
const StyledDrawer = styled(Drawer)`

  position: fixed;
  left: 0;
  right: 0;
  /* bottom: 0; */
  top: 62px;
  background: #1b1d2a;
  text-align: left;
  padding: 30px;
  height: auto;
  box-shadow: #bdbdbd40 -2px 17px 12px 0px;
  &.open{
    display:block;
  }
  &.closed{
    display:none;
  }
  button{
    float: right;
    top: 15px;
    position: fixed;
    right: 20px;
    background: #1b1c2a;
    color: white;
    font-size: 20px;
    border: none;
  }
  `;

const Header = ({ menuLinks }) => { 
  const [visible, toggledrawer] = useState(false);

 

  
  return (
  <StyledHeader>
    <StyledContainer>
      <StyledHomeLink title="logo" to="/">
       {/*  <StyledLogo /> */}
      BUHA Office
      </StyledHomeLink>
      <StyledNav>
        {menuLinks.map((link, index) => (
          <StyledNavLink key={link.name} to={link.link} activeClassName="active">
           {/*  <StyledIndexNumber>{`${String(index + 1).padStart(2, '0')}.`}</StyledIndexNumber> */}
            {link.name}
          </StyledNavLink>
        ))}
       
      </StyledNav>
      <StyledMobileNav>
          <Button  onClick={() => toggledrawer(true)}> <AlignRightOutlined /></Button>
          <StyledDrawer
          placement="top"
          closable={true}
          className={visible ? 'open' : 'closed'}
          onClose={() => toggledrawer(false)}
          visible={visible}
          key
        >
          {menuLinks.map((link, index) => (
         <p> <Link key={link.name} to={link.link} activeClassName="active" onClick={() => toggledrawer(false)}>
           {/*  <StyledIndexNumber>{`${String(index + 1).padStart(2, '0')}.`}</StyledIndexNumber> */}
            {link.name}
          </Link></p>
        ))}
         
        </StyledDrawer>
      </StyledMobileNav>
     {/*  <SocialIcons icons={socialIconList} /> */}
    </StyledContainer>
  </StyledHeader>
)};

export default Header;

const menuLinksPropTypeShape = PropTypes.shape({
  name: PropTypes.string,
  link: PropTypes.string,
});

Header.propTypes = {
  menuLinks: PropTypes.arrayOf(menuLinksPropTypeShape).isRequired,
};
